import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  makeStyles,
  Paper,
  TextField,
  useTheme
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { t, Trans } from '@lingui/macro'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { Copyright, NewsFrame } from '../Login/SignSideForm'
import useChangePasswordValidate, { Fields } from './useChangePasswordValidate'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    width: '230px'
  },
  fullWidth: {
    width: '100%'
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))
const defaultsValues = { newPassword: '', confirmNewPassword: '' }

export interface ChangePasswordPublicFormProps {
  responseApiError: boolean
  handleChangePassword: (passwordRecoverytoken: string, newPassword: string) => void
  CleanUseChangePassword: () => void
  isCompleted: boolean
  messageError: string
}
const ChangePasswordPublicForm: React.FC<ChangePasswordPublicFormProps> = props => {
  const classes = useStyles()
  const theme = useTheme()
  const [showPassword, setShowPassword] = useState(false)
  const [values, setValues] = useState<Fields>(defaultsValues)
  const { validations, isError, CleanValidations, messageConfig, passwordRecoverytoken } = useChangePasswordValidate(
    values
  )

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleChange = (prop: keyof Fields) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  useEffect(() => {
    if (props.isCompleted) {
      CleanValidations()
      setValues(defaultsValues)
    }
  }, [props.isCompleted])

  useEffect(() => {
    if (validations.isValid) {
      props.CleanUseChangePassword()
    }
  }, [validations.isValid])

  const sendhanleChange = () => {
    props.handleChangePassword(passwordRecoverytoken, values.newPassword)
  }
  return (
    <Grid container={true} component='main' className={classes.root}>
      <NewsFrame />
      <Grid item={true} xs={12} sm={12} md={5} lg={4} xl={3} component={Paper} elevation={6} square={true}>
        <div className={classes.paper}>
          {theme.palette.type === 'light' ? (
            <img className={classes.avatar} alt='' src='images/login/imedicalcloud-logo-color.png' />
          ) : (
            <img className={classes.avatar} alt='' src='images/login/logo-transparente.png' />
          )}
          {props.isCompleted ? (
            props.responseApiError ? (
              <Alert id='messageConfig' variant='outlined' severity={'error'} className={classes.fullWidth}>
                {props.messageError}
              </Alert>
            ) : (
              <Alert id='messageConfig' variant='outlined' severity='success' className={classes.fullWidth}>
                Contraseña actualizada con éxito
              </Alert>
            )
          ) : (
            <Alert
              id='messageConfig'
              variant='outlined'
              severity={isError ? 'error' : 'warning'}
              className={classes.fullWidth}
            >
              {messageConfig}
            </Alert>
          )}
          <FormControl fullWidth={true}>
            <TextField
              id='input-new-password'
              data-testid='input-new-password'
              type={showPassword ? 'text' : 'password'}
              fullWidth={true}
              value={values.newPassword}
              onChange={handleChange('newPassword')}
              error={validations.newPasswordVal !== ''}
              helperText={validations.newPasswordVal}
              label={t`Nueva Contraseña`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
          <FormControl fullWidth={true}>
            <TextField
              id='input-confirm-new-password'
              data-testid='input-confirm-new-password'
              type={showPassword ? 'text' : 'password'}
              fullWidth={true}
              value={values.confirmNewPassword}
              onChange={handleChange('confirmNewPassword')}
              error={validations.confirmNewPasswordVal !== ''}
              helperText={validations.confirmNewPasswordVal}
              label={t`Confirmar Nueva Contraseña`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
          <Grid
            container={true}
            alignItems='flex-start'
            justifyContent='flex-end'
            direction='row'
            style={{ marginTop: '1rem' }}
          >
            <Link href='/main' variant='body2' color='secondary'>
              <Trans>Ir al inicio de sesión</Trans>
            </Link>
          </Grid>
          <Button
            id='change-password-button'
            onClick={sendhanleChange}
            fullWidth={true}
            variant='contained'
            color='primary'
            className={classes.submit}
            disabled={!validations.isValid}
          >
            <Trans>cambiar</Trans>
          </Button>
          <Box mt={2}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  )
}

export default ChangePasswordPublicForm
