import { useState } from 'react'
import { GetDataError } from 'restful-react'
import { APIError, RequestChangePassword, usePostChangePassword } from '../../api/api'
import { KEY_PUBLIC } from '../../config'
import { encryptWithPublicKey } from '../../security/UtilEncode'

const useChangePasswordPublic = () => {
  const [isCompleted, setIsCompleted] = useState(false)
  const [responseApiError, setResponseApiError] = useState(false)
  const changePasswordApi = usePostChangePassword({})
  const [messageError, setMessageError] = useState('')

  const CleanUseChangePassword = () => {
    setResponseApiError(false)
    setIsCompleted(false)
    setMessageError('')
  }

  const handleChangePassword = (passwordRecoverytoken: string, newPassword: string) => {
    let newPasswordUser = newPassword
    if (KEY_PUBLIC !== '' && KEY_PUBLIC !== null) {
      newPasswordUser = encryptWithPublicKey(newPassword, KEY_PUBLIC) ?? ''
    }
    setIsCompleted(false)
    const changePassword: RequestChangePassword = { passwordRecoverytoken, newPassword: newPasswordUser }
    changePasswordApi
      .mutate(changePassword)
      .then(() => {
        setResponseApiError(false)
      })
      .catch((e: GetDataError<void | APIError>) => {
        setResponseApiError(true)
        setMessageError((e.data as APIError).message_error ?? '')
      })
      .finally(() => setIsCompleted(true))
  }

  return { handleChangePassword, CleanUseChangePassword, responseApiError, isCompleted, messageError }
}

export default useChangePasswordPublic
