import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { PasswordConfigResponse, useGetValidateRecoveryPasswordToken } from '../../api/api'

const TokenQueryParam = 'token'
export type Fields = {
  newPassword: string
  confirmNewPassword: string
}

export type FieldsValidations = {
  newPasswordVal: string
  confirmNewPasswordVal: string
  isValid: boolean
}

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const useChangePasswordValidate = (values: Fields) => {
  const query = useQuery()
  const passwordRecoverytoken = query.get(TokenQueryParam) ?? ''

  const getconfigPasswordApi = useGetValidateRecoveryPasswordToken({
    queryParams: { passwordRecoverytoken },
    lazy: passwordRecoverytoken === ''
  })
  const [validations, setValidations] = useState({
    newPasswordVal: '',
    confirmNewPasswordVal: '',
    isValid: false
  })

  const [config, setConfig] = useState<PasswordConfigResponse>({
    maxPasswordLen: 16,
    minPasswordLen: 6,
    validChars: ''
  })

  const [messageConfig, setMessageConfig] = useState('')
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (!getconfigPasswordApi.loading && getconfigPasswordApi.response?.ok && getconfigPasswordApi.data) {
      setConfig(getconfigPasswordApi.data)
    }

    if (getconfigPasswordApi.error) {
      setIsError(true)
    }
  }, [getconfigPasswordApi.loading])

  useEffect(() => {
    setMessageConfig(
      `Tenga en cuenta que la nueva Contraseña debe contener mínimo ${config.minPasswordLen} y máximo ${config.maxPasswordLen} Caracteres, entre los cuales debe incluir Números, letras y un carácter especial valido (${config.validChars}).`
    )
  }, [config.minPasswordLen, config.maxPasswordLen, config.validChars])

  useEffect(() => {
    if (isError) {
      setMessageConfig('Ha expirado el tiempo para la recuperación de contraseña')
    }
  }, [isError])

  const CleanValidations = () => {
    setValidations(prevValidations => ({
      ...prevValidations,
      newPasswordVal: '',
      confirmNewPasswordVal: '',
      isValid: false
    }))
  }

  useEffect(() => {
    CleanValidations()

    if (values.newPassword !== '' && values.newPassword.length < config.minPasswordLen) {
      setValidations(prevValidations => ({
        ...prevValidations,
        newPasswordVal: `Su nueva contraseña debe tener una longitud mínima de ${config.minPasswordLen} caracteres`
      }))
      return
    }

    if (values.newPassword !== '' && values.newPassword.length > config.maxPasswordLen) {
      setValidations(prevValidations => ({
        ...prevValidations,
        newPasswordVal: `Su nueva contraseña debe tener una longitud maxima de ${config.maxPasswordLen} caracteres`
      }))
      return
    }

    if (
      values.newPassword !== '' &&
      values.confirmNewPassword !== '' &&
      values.newPassword !== values.confirmNewPassword
    ) {
      setValidations(prevValidations => ({
        ...prevValidations,
        confirmNewPasswordVal: `La nueva contraseña debe ser igual a la confirmada`
      }))
      return
    }

    if (
      values.newPassword !== '' &&
      values.confirmNewPassword !== '' &&
      values.newPassword === values.confirmNewPassword &&
      !isError
    ) {
      setValidations(prevValidations => ({
        ...prevValidations,
        isValid: true
      }))
    }
  }, [values])

  return { validations, CleanValidations, messageConfig, isError, setIsError, passwordRecoverytoken }
}

export default useChangePasswordValidate
